<template>
  <b-pagination
    class="is-primary mb-6 mt-1 px-1"
    is-small
    :range-before="rangeBefore"
    :range-after="rangeAfter"
    :order="order"
    :size="size"
    :simple="isSimple"
    :rounded="isRounded"
    :total="total"
    :value="value"
    @input="onChange"
    :current.sync="pageNew"
    :per-page="perPage"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    @change="onChange"
    >
  </b-pagination>
</template>

<script>


export default {
  name: 'app-pagination',
  props: {
    value: {
      default: null
    },
    total: {
      default: 0
    },
    page: {
      default: 1
    },
    perPage: {
      default: 15
    }
  },

  data () {
    return {
      // page update cause 'avoid mutating ...' error
      pageNew: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: '',
      size: '',
      isSimple: false,
      isRounded: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    }
  },

  methods: {
    /**
      * Handle page-change event
     */
    onChange(page) {
      this.pageNew = page
      this.$emit('onPageChange', this.pageNew)
      // console.log('onChange: ', page, this.pageNew);
    },

  },

  created () {
    this.pageNew = this.page
  }
}
</script>