<template>
  <div>
    <app-modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      :trash-object-deleted="trashObjectDeleted"
      @confirm="confirmDeleteItem"
      @cancel="cancel"
    />
    <!-- {{paginationTop}} {{page}} {{total}} {{pagination}} -->
    <!-- {{deleteRoute}} -->
    <app-pagination :page="page" :total="total" v-model="pagination" @onPageChange="onChange" />

    <b-table v-if="!(items === null && data === null)"
      @page-change="onChange"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="data || items"
    >

      <template slot-scope="props">
        <b-table-column v-for="(file,title) in columns" :key="title"
          :label="translate(file,title)"
          :field="title"
          sortable
        >
          <span class="tag" :class="type(props, title)" v-if="title === 'status'">{{ getValue(props, title) }}</span>
          <span v-else>{{ getValue(props, title) }}</span>

        </b-table-column>

        <b-table-column v-if="hasAccess" custom-key="actions" class="is-actions-cell" width="120">
          <div class="is-right is-inline-block">
            <router-link
              v-if="editRoute !== null"
              :to="{
                name: editRoute,
                params: { slug: props.row.slug },
              }"
              class="button is-text is-inline-block"
            >
              <b-icon icon="account-edit" type="is-grey-light" />
            </router-link>
            <router-link
              v-if="viewRoute !== null"
              :to="{
                name: viewRoute,
                params: { slug: props.row.slug },
              }"
              class="button is-text is-inline-block"
            >
              <b-icon icon="mdi mdi-eye" type="is-grey-light" />
            </router-link>
            <button
              class="button is-text is-inline"
              @click.prevent="deleteModal(props.row)"
              v-if="props.row.deleted !== null"
              title="destroy, delete forever"
            >
              <b-icon icon="delete-forever-outline" type="is-danger" />
            </button>
            <button
              v-else
              class="button is-text is-inline-block"
              @click.prevent="deleteModal(props.row)"
              title="delete"
            >
              <b-icon icon="mdi mdi-trash-can-outline" type="is-danger" />
            </button>
          </div>
        </b-table-column>
      </template>
    </b-table>

    <app-pagination v-if="paginationBottom && total > perPage" :page="page" :total="total" v-model="pagination" @onPageChange="onChange" />

  </div>
</template>

<script>
// Columns can be arrays or objects (including link)
// backend should return the name of the field as it is saved in translations,
// e.g (survey_instance, name, ...)
// if we need link, returned from backend should be -survey_instance_link, name_link, ...
import { ApiPermissions } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Pagination from '@/components/elements/table/Pagination'


export default {
  name: 'app-table',
  mixins: [ListTemplateMixin],
  props: {
    columns: {
      type: Object,
      default: null
    },
    data: {
      default: null
    },
    editRoute: {
      default: null
    },
    viewRoute: {
      default: null
    },
    deleteRoute: {
      default: null
    },
    page: {
      default: 1
    },
    total: {
      default: 0
    },
    paginationTop: {
      default: false
    },
    paginationBottom: {
      default: true
    },
    hasAccess: {
      default: true
    }
  },

  components: {
    'app-pagination': Pagination
  },

  data () {
    return {
      //
    }
  },

  methods: {
    confirmDeleteItem() {
      // console.log(this.deleteRoute) // to
      this.confirmDelete(this.deleteRoute)
    },
    // key has to be in from.json as label
    // having structure: form.name.label'
    // keys are: name:form, email:form, created:general, ...
    // For now not in use, but we need to consider how to
    // change language !!!
    translate (v, k) {
      if (v === 'form') {
        return this.$getTranslation('form.' + k + '.label')
      }
      return this.$getTranslation('form.general.' + k)
    },

    /**
     * get value for column
     *
     * @param  {string} props value of the column
     * @param  {string} name name of the column
     *
     */
    getValue (props, name) {
      if(props.row.hasOwnProperty(name)) {
        if (props.row[name] === null) {
          return '-'
        }

        // userName, userSlug
        if (name === 'created_by') {
          return props.row.userName
        }
        // console.log('name: ', name)
        return props.row[name]
      }
      if (name === 'survey_instance') {
        return props.row.survey_instance
      }
      if (name === 'projects_title') {
        return props.row.projects.title
      }

    },
    type(props, name) {
      const val = props.row[name]
      // console.log(val.isNumeric)
      if (typeof(val) === 'number') {
        if (val < 1) {
          // status for messages sent or not
          return 'is-warning'
        } else {
          return 'is-success'
        }
      } else {
        // status for reporting
        if (val === 'review') {
          return 'is-warning'
        } else {
          return 'is-success'
        }
      }
    },

    /**
      * Handle page-change event
     */
    onChange(page) {
      this.$emit('onPageChange', page)
    },
  },

  created () {
    // console.log('Table created ', this.items, ' paginations: ', this.pagination)
  }
}
</script>
