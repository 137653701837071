<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.invitation', 1, true) }}
    </hero-bar>

    <section class="section is-main-section capitalize">
      <b-button
        tag="router-link"
        :to="{ name: 'invitations.create' }"
        type="is-primary"
        icon-left="plus"
      >{{ $getTranslation('form.send') }}
        {{ $getTranslation('general.views.invitation', 1) }}
      </b-button>

    </section>

    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" icon="account-multiple">
        <div v-if="!loading && items">

          <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :editRoute="editRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
          />
        </div>
        <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ApiInvitations, ApiPermissions, ApiRoles } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'invitations',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },
  data () {
    return {
      endpoint: ApiInvitations,
      moduleName: this.$route.meta.type,
      editRoute: 'invitations.edit',
      total: 0,
      page: 1,
      columns: {
        'name': 'form',
        'email': 'form',
        'accepted': 'form',
        'created': 'general',
        'date_accepted': 'general',
        'deleted': 'general'
      }
    }
  },
  computed: {
    ...mapGetters([
      "items",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchItems"
    ]),

  },
  mounted () {
    // console.log('Activities mounted')
  },
  created () {
    this.fetchItems(this.endpoint)
      .then(response => {
        //
      })
      .catch(error => {
        this.$toast.danger(`Error: ${e.message}`)
        console.log('ERROR form submit: ', error);
      });
    // console.log('LogActivities created')
  }
}
</script>
